<template>
    <div class="content">
        <div class="content-head">
            <p class="headtime">活动时间：{{ time(beginTime) }}-{{ time(endTime) }}</p>
            <div class="horselamp" :style="`width:${redswidth * 2.2}px;`">
                <div :style="`margin-left:${-gotime}px;`">
                    <div v-for="(item, index) in horselamp" :key="index" ref="reds">
                        <img :src="item.avatar ? item.avatar : 'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'"
                            alt="">
                        <span>{{ item.msg }}</span>
                    </div>
                </div>
                <div>
                    <div v-for="(item, index) in horselamp" :key="index">
                        <img :src="item.avatar ? item.avatar : 'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'"
                            alt="">
                        <span>{{ item.msg }}</span>
                    </div>
                </div>
            </div>
            <div class="guize">
                <div @click="guize"></div>
                <div @click="doload"></div>
            </div>
            <div class="majiang">
                <img v-for="(item,index) in majianglist" :key="index" :src="item!==0?item.img:`https://ppyos.xijiuyou.com/2401/mj/mj-pb.png`" alt="" />
                <!-- <img src="https://ppyos.xijiuyou.com/2401/mj/mj-pb.png" alt="" />
                <img src="https://ppyos.xijiuyou.com/2401/mj/mj-pb.png" alt="" /> -->
                <p v-if="!isiOS">下载后送您3次摸牌机会 <br/> 还有其他好礼等你拿</p>
            </div>
            <div class="mopaibtn">
                <div @click="lotty">
                    <img :src="availableChance<1?'https://ppyos.xijiuyou.com/2401/invite-download-join-btn.png':'https://ppyos.xijiuyou.com/2401/invite-btn-img.png'" alt="" />
                    <div v-if="availableChance > 0">{{ availableChance }}</div>
                </div>
                <p v-if="availableChance > 0">剩余{{ availableChance }}次</p>
                <p v-if="availableChance <= 0 && isiOS">下载后进入活动赠送您3次摸牌机会 <br/> 还有其他好礼等你拿</p>
            </div>
        </div>
        <div class="task">
            <div class="Winnings">
                <img class="Winningstitle" src="https://ppyos.xijiuyou.com/2401/invite-px-txt.png" alt="">
                <div class="Winningscontent">
                    <div class="titles">
                        <p>全牌型(8888元）</p>
                        <img src="https://ppyos.xijiuyou.com/2401/mj-example-3.png" alt="">
                    </div>
                    <div class="titles">
                        <p>东南西北(18.8元）</p>
                        <div>
                            <img src="https://ppyos.xijiuyou.com/2401/mj-dong.png" alt="" />
                            <img src="https://ppyos.xijiuyou.com/2401/mj-nan.png" alt="" />
                            <img src="https://ppyos.xijiuyou.com/2401/mj-xi.png" alt="" />
                            <img src="https://ppyos.xijiuyou.com/2401/mj-bei.png" alt="" />
                        </div>
                    </div>
                    <div class="Winningtitles">
                        <div>
                            <p>发财(3.8元）</p>
                            <img src="https://ppyos.xijiuyou.com/2401/mj-fa.png" alt="">
                        </div>
                        <div>
                            <p>红中(1.8元）</p>
                            <img src="https://ppyos.xijiuyou.com/2401/mj-zhong.png" alt="">
                        </div>
                    </div>
                    <div class="titles">
                        <p>三张相同牌(限万、筒、条牌)(0.18元)</p>
                        <img class="titlesimgs" src="https://ppyos.xijiuyou.com/2401/mj-example-1.png" alt="">
                    </div>
                    <div class="titles">
                        <p>三张同组牌(限万、筒、条牌)(0.18元)</p>
                        <img class="titlesimgs1" src="https://ppyos.xijiuyou.com/2401/mj-example-2.png" alt="">
                    </div>
                </div>
                <img  class="guanb" src="https://ppyos.xijiuyou.com/202301/close-icon-img.png" alt="">
            </div>
        </div>
        <xwpopup :isshowpopup="guaj" @close="closephones()" :hiddenClose="false">
            <div class="container" id="top">
                <div class="award_box">
                    <img class="maj" :src="succcontent.img" alt="">
                    <img class="smallbanner" src="https://ppyos.xijiuyou.com/202301/red-small-banner.png" alt="">
                    <p class="award_mj">{{succcontent.luckyText}}</p>
                    <img class="btnimg" @click="closephones" src="https://ppyos.xijiuyou.com/2401/invite-continue-btn.png" alt="">
                </div>
            </div>
        </xwpopup>
        <div id="wchat">
            <img src="https://bubblefish.oss-cn-hangzhou.aliyuncs.com/imgs/invite/brower_pointer.png" alt="">
            <img src="https://bubblefish.oss-cn-hangzhou.aliyuncs.com/imgs/invite/open_browser.png" alt="">
        </div>
        <input type="text" id="input" readonly="readonly">
    </div>
</template>
<script>
import dayjs from "dayjs";
import brand from "../../api/brand";
import xwpopup from "../../components/Popupbox.vue";
export default {
    components: {
        xwpopup,
    },
    data() {
        return {
            gotime: 0,
            redswidth: 0,
            horselamp: [],
            availableChance: 0,
            beginTime: "",
            endTime: "",
            shave: true,
            guaj:false,
            succcontent:{},
            majianglist:[
                0,0,0
            ],
            isiOS:true,
        };
    },
    mounted() {
        this.marquee();
        this.simulationinfo();
    },
    destroyed() {
        const that = this;
        clearInterval(that.times);
    },
    methods: {
        closephones(){
            this.guaj = !this.guaj;
        },
        doload(){
            var ua = window.navigator.userAgent.toLowerCase();
            const input = document.getElementById("input");
            input.value = window.location.href.split("code=")[1];
            input.select();
            document.activeElement.blur()
            document.execCommand("copy");
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                const toast = document.getElementById("wchat");
                toast.style.display = "block";
            }else{
                window.location.href = "https://ppyos.xijiuyou.com/apks/version/lasted/1111-ppyw_aligned_signed.apk";
            }
        },
        lotty(){
            if(this.availableChance < 1){
                this.doload();
                return false;
            }
            brand.lotty().then(res=>{
                this.succcontent = res.data;
               const index =  this.majianglist.findIndex(res=>res === 0);
               this.majianglist[index] = res.data;
                this.availableChance--;
                this.guaj = true;
            })
        },
        simulationinfo(){
            brand.simulationinfo().then(res=>{
                var ua = window.navigator.userAgent.toLowerCase();
                this.isiOS = ua.match(/MicroMessenger/i) == 'micromessenger';
                if(ua.match(/MicroMessenger/i) != 'micromessenger'){
                    this.availableChance = 0;
                }else{
                    this.availableChance = res.data.newbieSendChance;
                }
                this.endTime = res.data.endTime;
                this.beginTime = res.data.beginTime;
            })
        },
        time(item) {
            if (item) {
                return dayjs(item).format("MM月DD日");
            } else {
                return "";
            }
        },
        guize() {
            this.$router.push({
                name: "mjrule",
            });
        },
        gohorse() {
            const that = this;
            that.$nextTick(() => {
                if (this.horselamp.length < 1) {
                    return false;
                }
                that.$refs.reds.map(res => {
                    that.redswidth = that.redswidth + res.clientWidth;
                })
                that.redswidth = that.redswidth + (that.horselamp.length * 80) + 40
                that.times = setInterval(() => {
                    if (that.gotime >= that.redswidth) {
                        that.gotime = 0
                    } else {
                        that.gotime++
                    }
                }, 10)
            })
        },
        marquee() {
            brand.marquee().then(res => {
                this.horselamp = res.data;
                this.gohorse();
            })
        },
    }
};
</script>
<style lang="less" scoped>
#wchat {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.72);
    text-align: right;
    display: none;
    z-index: 10;
}
#wchat img:nth-child(1) {
    position: absolute;
    width: 60px !important;
    right: 40px !important;
    display: block;
    left: auto !important;
    bottom: auto !important;
    top: 30px !important;
}
#wchat img:nth-child(2) {
    position: absolute;
    width: 180px !important;
    right: 20px !important;
    top: 100px !important;
    display: block;
    left: auto !important;
    bottom: auto !important;
    margin: 0 !important;
}
.content {
    width: 100vw;

    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(270deg, #FFF3F5 0%, #FFEBDF 100%);

    .content-head {
        background: url("https://ppyos.xijiuyou.com/2401/mj-bg-img-8888.png") no-repeat;
        background-size: 100%;
        position: relative;
        padding-bottom: 66px;
        width: 100vw;
        overflow: hidden;
        box-sizing: border-box;
        z-index: 1;
    }

    .headtime {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
        position: relative;
        z-index: 2;
    }

    .guize {
        width: 33px;
        height: 167px;
        background: url("https://ppyos.xijiuyou.com/2401/invite-rule-txt.png") no-repeat;
        background-size: 100%;
        position: absolute;
        right: 7px;
        top: 0;
        z-index: 11;
        padding-top: 65px;

        div:nth-child(1) {
            width: 100%;
            height: 50px;
        }

        div:nth-child(2) {
            width: 100%;
            height: 50px;
        }
    }

    .majiang {
        display: flex;
        justify-content: center;
        margin-top: 220px;
        position: relative;
        img {
            width: 70px;
            height: 104px;
            margin: 0 7px;
        }
        p{
            position: absolute;
            width: calc(100% - 180px);
            background: rgba(0,0,0,0.7);
            border-radius: 10px;
            text-align: center;
            padding: 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            top: -10px;
        }
    }

    .mopaibtn {
        margin-top: 30px;
        text-align: center;

        div {
            position: relative;
            width: 157px;
            height: 52px;
            margin: 0 auto;

            img {
                width: 100%;
            }

            div {
                position: absolute;
                width: 22px;
                height: 22px;
                background: linear-gradient(180deg, #FFF5CE 0%, #FFE6A0 100%);
                font-size: 15px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FE8C12;
                border-radius: 50%;
                right: -5px;
                top: -5px;
                padding-top: 2.5px;
            }
        }

        p {
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            margin-top: 9px;
        }
    }
}


.horselamp {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 10;
    padding-top: 31px;
    display: flex;
}

.horselamp div div img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    float: left;
    margin: 4px;
}

.horselamp div div {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    height: 32px;
    background: rgba(240, 161, 104, 0.78);
    border-radius: 16px;
    box-sizing: border-box;
    margin-right: 80px;
}

.horselamp div {
    display: block;
}

.horselamp div div span {
    line-height: 32px;
    height: 32px;
    overflow: hidden;
    width: calc(100% - 32px);
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
    display: flex;
    align-items: center;
    padding-right: 4px;
}

.Winnings {
    background: linear-gradient(270deg, #FFF3F5 0%, #FFEBDF 100%);
    box-sizing: border-box;
    padding: 18px 8px 10px;
    text-align: center;
    margin-top: -10px;

    .guanb {
        position: absolute;
        right: 22px;
        top: 22px;
        width: 12px;
        height: 12px;
    }

    .Winningstitle {
        width: 126px;
        margin-bottom: 24px;
    }

    .titles {
        width: 100%;
        background: linear-gradient(360deg, #FFC5C5 0%, #FFE0D3 99%);
        border-radius: 10px;
        padding: 12px 0;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FF3500;
        margin-bottom: 8px;

        .titlesimgs {
            width: calc(100% - 18px);
        }

        .titlesimgs1 {
            width: calc(100% - 35px);
        }

        p {
            margin-bottom: 14px;
        }

        img {
            width: calc(100% - 68px);
        }

        div {
            display: flex;
            justify-content: center;

            img {
                width: 58px;
                height: 86px;
                margin: 0 5px;
            }
        }
    }

    .Winningtitles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        div {
            width: calc(50% - 5px);
            background: linear-gradient(360deg, #FFC5C5 0%, #FFE0D3 99%);
            border-radius: 10px;
            padding: 12px 0;

            p {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FF3500;
                margin-bottom: 14px;
            }

            img {
                width: 58px;
            }
        }
    }
}
.container {
  width: 100%;
  padding:190px 0 0;
  position: relative;
  .award_box {
    height: 252px;
    width: 263px;
    margin: 0px auto;
    background: url("https://ppyos.xijiuyou.com/2401/mj-gift-big-img.png") no-repeat;
    background-size: 100%;
    padding-top: 1px;
    .title{
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFF4D2;
    }
    .smallbanner{
      display: block;
      width: 143px;
      margin: -4px auto 7px;
    }
    .award_mj{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 30px;
        padding: 0 30px;
    }
    .btnimg{
        width: 157px;
        height: 56px;
        margin-top: 10px;
    }
    .maj{
      display: block;
      width: 78px;
      margin: -30px auto 0;
      position: relative;
      z-index: 1;
    }
  }
  }
        #input {
            position: fixed;
            right: 3000px;
            top: 0;
        }
  </style>